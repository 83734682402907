<template>

  <a-drawer
    :title=" title || (edit?'编辑':'新增')"
    :maskClosable="true"
    :width="width"
    placement="right"
    :closable="true"
    @close="hide"
    destroyOnClose
    :visible="visible">

    <a-form-model
      ref="ruleForm"
      style="margin-bottom: 60px"
      v-bind="formItemLayout"
      layout="horizontal"
      :rules="rules"
      :model="model">


      <a-form-model-item v-for="item in items" :label='item.name' :prop="item.key">

        <!--  单行文本  -->
        <a-input v-if="item.type=='input'" v-model="model[item.key]" :prefix="item.prefix" :suffix="item.suffix" :disabled="item.disable"/>

        <!--  多行文本  -->
        <a-textarea
          v-if="item.type == 'textarea' "
          :placeholder="item.placeholder"
          :auto-size="{ minRows: item.minRows || 4 }"
          v-model="model[item.key]"
        />

        <!--  单张图片  -->
        <image-upload v-if="item.type == 'image' " v-model="model[item.key]"></image-upload>


        <!--  富文本  -->
        <rich-text-field v-if="item.type == 'richtext' " v-model="model[item.key]"></rich-text-field>


        <!--  数字输入  -->
        <a-input-number v-if="item.type == 'number' " v-model="model[item.key]"></a-input-number>

        <!--  下拉选择  -->
        <a-select v-if="item.type == 'select' " v-model="model[item.key]">
          <a-select-option v-for="option in item.options||[]" :value="option.value">{{option.name}}</a-select-option>
        </a-select>

        <!--  开关  -->
        <a-switch v-if="item.type == 'switch' " v-model="model[item.key]" ></a-switch>

<!--        复选框-->
        <a-checkbox v-if="item.type == 'checkbox' " v-model="model[item.key]">{{item.hint}}</a-checkbox>

<!--        单选框-->
        <a-radio-group v-if="item.type == 'radio-button' " v-model="model[item.key]" >
          <a-radio-button v-for="option in item.options||[]" :value="option.value" >{{option.name}}</a-radio-button>
          &nbsp;&nbsp;{{item.hint}}
        </a-radio-group>

        <!--        单选框-->
        <a-radio-group v-if="item.type == 'radio' " v-model="model[item.key]">
          <a-radio style="display: block;height: 30px;line-height: 30px" v-for="option in item.options||[]" :value="option.value">{{option.name}}</a-radio>
        </a-radio-group>

<!--        下拉树选择-->
        <a-tree-select
          v-if="item.type == 'tree-select' " v-model="model[item.key]"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="item.treeData||[]"
          placeholder=""
          allowClear
          showSearch
          tree-default-expand-all >
        </a-tree-select>

<!--        联级选择-->
        <a-cascader
          v-if="item.type == 'cascader' " v-model="model[item.key]"
          :options="item.treeData||[]"
          placeholder="Please select"
          expandTrigger="hover"
          change-on-select
        />

<!--        图标选择-->
        <icon-select v-if="item.type == 'icon-select' " v-model="model[item.key]"></icon-select>

<!--        颜色选择-->
        <color-picker v-if="item.type == 'color' " v-model="model[item.key]"></color-picker>

<!--        地址选择-->
        <location-picker v-if="item.type == 'location' " v-model="model[item.key]"></location-picker>

<!--        树选择-->
        <menu-tree v-if="item.type == 'tree' " v-model="model[item.key]"
                   :tree-data="item.treeData||[]"></menu-tree>

<!--        密码输入/修改-->
        <password-input v-if="item.type == 'password' " v-model="model[item.key]" :edit="edit"></password-input>



<!--        Slot-->
        <slot v-if="item.slot" :name="item.slot"></slot>

      </a-form-model-item>


    </a-form-model>



    <div class="drawer-bottom-button">
      <slot name="action"></slot>
      <a-button style="margin-right: .8rem" @click="hide">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>

  </a-drawer>


</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      width: {
        type: Number,
        default: 650
      },
      url: {
        type: String,
        default: ''
      },
      contentType: {
        type: String,
        default: ''
      },
      edit: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
        default: () => []
      },
      beforeRequest: {
        type: Function,
        default: null
      },
    },
    data() {
      return {
        formItemLayout: {
          labelCol: {span: 5},
          wrapperCol: {span: 18},
        },
        visible: false,
        loading: false,
        model: {}
      }
    },
    computed: {
      drawerTitle(){

      },
      rules() {
        let rules = {}
        this.items.forEach(item => {
          let rule = []
          if (item.required) {
            rule.push({required: true, message: '必填项'})
          }
          if (item.min) {
            rule.push({min: item.min, message: `长度不少于${item.min}个字符`})
          }
          if (item.max) {
            rule.push({max: item.max, message: `长度不能超过${item.max}个字符`})
          }
          rules[item.key] = rule
        })
        return rules
      }
    },
    methods: {
      show(model={}) {
        this.model = JSON.parse(JSON.stringify(model))
        this.visible = true
      },
      hide() {
        this.visible = false
      },
      handleSubmit() {
        this.$refs.ruleForm.validate(valid => {
          if(!valid){
            return
          }
          let model = this.beforeRequest ? this.beforeRequest(this.model) : this.model
          if(!model){
            return
          }
          this.edit ? this.requestEdit(model) : this.requestAdd(model)
        })
      },
      requestAdd(model){
        this.loading = true
        this.$post(this.url, model, this.contentType=="json").then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },
      requestEdit(model){
        this.loading = true
        this.$put(this.url, model, this.contentType=="json").then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .drawer-bottom-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 2px 2px;
    z-index: 10;
  }


</style>
