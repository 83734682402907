<template>
    <div class="page-content">

        <a-page-header v-if="title" :title="title"  @back="() => $router.back()" class="page-header">
            <template slot="extra">
                <slot name="title-extra"></slot>
            </template>
        </a-page-header>
        <a-card :bordered="false" style="background: transparent">
            <slot></slot>
        </a-card>
    </div>
</template>

<script>
    export default {
        name: "PageContent",
        props:["title"],
    }
</script>

<style lang="less" scoped>
.page-content{
    width: 100%;
    min-height: 100%;
    background: rgba(255,255,255,0.92);

    .page-header{
        background: transparent;
        position: relative;
        top: 10px;
    }
}
</style>
