<template>
    <div>

        <Search
                v-show="search"
                :placeholder="searchPlaceholder"
                @search="handleSearch"
                @reset="handleReset">

            <!--      <a-button type="primary" ghost @click="add" v-hasPermission="['user:add']">新增</a-button>-->


            <RangeDatePicker style="width: 300px;float:right" v-if="dateRange" ref="rangeDatePicker"
                               @change="dateChange"></RangeDatePicker>

            <slot name="search"></slot>

        </Search>

        <slot name="header"></slot>

        <a-table ref="TableInfo"
                 :columns="tableColumns"
                 :dataSource="dataSource"
                 :pagination="pagination"
                 :loading="loading"
                 rowKey="id"
                 :rowSelection="tableRowSelection"
                 @change="handleTableChange"
                 size="middle"
                 :defaultExpandAllRows="true">
            <template
                    v-for="tableSlot in tableSlots "
                    :slot="tableSlot"
                    slot-scope="text, record">

                <slot :name="tableSlot" :record="record"></slot>

            </template>
        </a-table>
    </div>


</template>

<script>

    import RangeDatePicker from './RangeDatePicker'
    import Search from './Search'

    const pagination = function(){
        return {
            pageSizeOptions: ['10', '20', '50', '100'],
            defaultCurrent: 1,
            current: 1,
            total: 0,
            pageSize: 10,
            defaultPageSize: 10,
            showQuickJumper: false,
            showSizeChanger: true,
            showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
        }
    }

    export default {
        components: {RangeDatePicker,Search},
        props: {
            pageable: {
                type: Boolean,
                default: true
            },
            tree: {
                type: Boolean,
                default: false
            },
            search: {
                type: Boolean,
                default: true
            },
            searchPlaceholder: {
                type: String,
                default: "搜索"
            },
            columns: {
                type: Array,
                default: () => []
            },
            queryParams: {
                type: Object,
                default: () => {
                }
            },
            path: {
                type: String,
                default: ''
            },
            rowSelectable: {
                type: Boolean,
                default: false
            },
            dateRange: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                keyword: '',
                dataSource: [],
                loading: false,
                selectedRowKeys: [],
                paginationInfo: pagination(),
                filteredInfo: '',
                sortedInfo: '',

                timeFrom: 0,
                timeTo: 0
            }
        },
        mounted() {
            setTimeout(() => {
                this.getData()
            }, 200)
        },
        computed: {
            pagination() {
                return this.pageable ? this.paginationInfo : false
            },
            tableSlots() {
                let slots = []
                this.columns.forEach(column => {
                    if (column.scopedSlots && column.scopedSlots.customRender) {
                        slots.push(column.scopedSlots.customRender)
                    }
                })
                return slots
            },
            tableColumns() {

                let {sortedInfo, filteredInfo} = this
                sortedInfo = sortedInfo || {}
                filteredInfo = filteredInfo || {}

                var columns = this.columns
                columns.forEach(column => {
                    if (column.sorter) {
                        column.sortOrder = sortedInfo.columnKey === column.dataIndex && sortedInfo.order
                    }
                    if (column.filters) {
                        column.filteredValue = filteredInfo[column.dataIndex] || null
                    }
                })

                return columns
            },
            tableRowSelection() {
                if (this.rowSelectable) {
                    return {
                        selectedRowKeys: this.selectedRowKeys,
                        onChange: (selectedRowKeys) => this.selectedRowKeys = selectedRowKeys
                    }
                } else {
                    return null
                }
            }


        },
        methods: {
            getQueryParams() {
                let {sortedInfo, filteredInfo} = this
                let sortField, sortOrder
                // 获取当前列的排序和列的过滤规则
                if (sortedInfo) {
                    sortField = sortedInfo.field
                    sortOrder = sortedInfo.order
                }

                let paras = {
                    keyword: this.keyword,
                    pageNum: this.paginationInfo.current,
                    pageSize: this.paginationInfo.pageSize,
                    sortField: sortField || "",
                    sortOrder: sortOrder || "",
                    ...this.queryParams,
                    ...filteredInfo
                }

                if (this.timeTo > 0) {
                    paras.timeFrom = this.timeFrom
                    paras.timeTo = this.timeTo
                }
                return paras
            },
            export(fileName) {
                let params = this.getQueryParams()
                params.pageNum = 1
                params.pageSize = 1000000
                this.$export(this.path + '/excel', params, `${fileName}_${new Date().getTime()}.xlsx`)
            },
            dateChange(timeFrom, timeTo) {
                this.timeFrom = timeFrom
                this.timeTo = timeTo

                this.getData()
            },

            handleTableChange(pagination, filters, sorter) {
                // 将这三个参数赋值给Vue data，用于后续使用
                this.paginationInfo = pagination
                this.filteredInfo = filters
                this.sortedInfo = sorter
                this.getData()
            },
            handleSearch(keyword) {
                this.keyword = keyword

                const paginationInfo = {...this.paginationInfo}
                paginationInfo.current = 1
                this.paginationInfo = paginationInfo

                this.getData()
            },
            resetPage(){
                this.paginationInfo = pagination()
            },
            getData() {
                this.loading = true
                setTimeout(()=>{
                    this.$get(this.path, this.getQueryParams()).then((r) => {
                        this.loading = false
                        let data = r.data

                        if (this.tree) {
                            this.dataSource = data.rows.children
                        } else if (this.pageable) {
                            this.dataSource = data.rows
                        } else {
                            this.dataSource = data
                        }

                        const paginationInfo = {...this.paginationInfo}
                        paginationInfo.total = data.total
                        this.paginationInfo = paginationInfo

                    }).catch(e => {
                        this.loading = false
                    })
                },100)


            },
            handleReset() {

                this.$emit('reset')

                if (this.$refs.rangeDatePicker) {
                    this.$refs.rangeDatePicker.clear()
                }

                // 取消选中
                this.selectedRowKeys = []
                // 重置分页
                this.paginationInfo = pagination()
                // 重置列过滤器规则
                this.filteredInfo = null
                // 重置列排序规则
                this.sortedInfo = null
                // 重置查询参数
                this.keyword = ''

                this.timeFrom = 0
                this.timeTo = 0

                this.getData()

            },

        }
    }
</script>
<style>

    .ant-table-thead > tr > th{

    }
</style>
