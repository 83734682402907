<template>
  <page-content>

    <a-row :gutter="[16,16]">
      <a-col :span="10">
        <StatisticsData style="background: #D9F1FF; color: #666666"
                        title="在线设备数"
                        :icon="require('../../assets/home/ic_home_online.png')"
                        :value="statistics.onlineDeviceNum">
          <OnlinePie ref="onlinePie"></OnlinePie>
        </StatisticsData>
      </a-col>
      <a-col :span="7">
        <StatisticsData
            class="clickable"
            @click="clickDeviceTotal"
                        style="background: #00BFFF; color: #FFF"
                        title="设备总数"
                        :icon="require('../../assets/home/ic_home_device.png')"
                        :value="statistics.totalDeviceNum">
        </StatisticsData>
      </a-col>
      <a-col :span="7">
        <StatisticsData
            class="clickable"
            @click="clickDeviceFault"
            style="background: #ffb700; color: #FFF;"
                        title="报警设备数"
                        :icon="require('../../assets/home/ic_home_alert.png')"
                        :value="statistics.alarmDeviceNum">
        </StatisticsData>
      </a-col>
      <!--            <a-col :span="5">-->
      <!--                <StatisticsData style="background: #32CD32; color: #FFF;"-->
      <!--                                title="用户总数"-->
      <!--                                :icon="require('../../assets/home/ic_home_user.png')"-->
      <!--                                :value="statistics.totalUserNum" >-->
      <!--                </StatisticsData>-->
      <!--            </a-col>-->

      <a-col :span="24">
        <div class="map-area" v-bind:class="{'full-screen':fullScreen}">
          <AMap ref="bmap"></AMap>
          <a-button class="btn-full-screen" @click="clickFullScreen" type="default"
                    :icon="fullScreen?'fullscreen-exit':'fullscreen'"></a-button>
        </div>
      </a-col>

      <!--            <a-col :span="24">-->
      <!--                <DistributionChart ref="distributionChart"></DistributionChart>-->
      <!--            </a-col>-->

    </a-row>

  </page-content>
</template>
<script>


import {mapState} from "vuex";

import AMap from "./_components/AMap"
import StatisticsData from "./_components/StatisticsData";
import OnlinePie from "./_components/OnlinePie";
import DistributionChart from "./_components/DistributionChart";
import ScreenUtil from "../../utils/screen"

export default {
  name: "HomePage",
  components: {
    StatisticsData,
    OnlinePie,
    DistributionChart,
    AMap
  },
  data() {
    return {
      statistics: {
        totalDeviceNum: "--",
        onlineDeviceNum: "--",
        alarmDeviceNum: "--",
        totalUserNum: "--",
      },
      dis: [],
      fullScreen:false

    };
  },
  computed: {
    ...mapState({
      multipage: (state) => state.setting.multipage,
      user: (state) => state.account.user,
      vendor: (state) => state.account.vendor,
    }),
    avatar() {
      return `static/avatar/${this.user.avatar}`;
    },
  },
  methods: {
    clickDeviceTotal(){
      this.$router.push("/device")
    },
    clickDeviceFault(){
      this.$router.push("/iot-fault")
    },
    clickFullScreen() {
      this.fullScreen = !this.fullScreen
      if (this.fullScreen) {
        ScreenUtil.fullScreen()
      } else {
        ScreenUtil.exitScreen()
      }
    },
  },
  mounted() {

    // setTimeout(()=>{
    //   this.$refs.bmap.update([], [], [])
    // },500)


    this.$get("admin/home/statistics").then((r) => {
      let data = r.data;
      this.statistics = data;
      this.$refs.onlinePie.updateOnlinePie(data.onlineDeviceNum, data.totalDeviceNum - data.onlineDeviceNum)
    })

    this.$get("admin/home/deviceForMap").then(res => {
      let onlineData = []
      let offlineData = []
      let alarmData = []
      res.data.forEach(device => {
        if (device.address && device.longitude && device.latitude) {
          let item = {
            nickname: device.nickname,
            name: device.name,
            address: device.address,
            value: [device.longitude, device.latitude]
          }

          if (!device.online) {
            item.statusName = "离线"
            offlineData.push(item)
          } else if (!device.alarm) {
            item.statusName = "在线"
            onlineData.push(item)
          } else {
            let errArray = ["1","2","3","4","5","6","7","8","9",
              "A","B","0","D","E","F","C","H"]
            item.statusName = "故障报警 E"+ errArray[device.errorCode-1]
            alarmData.push(item)
          }
        }

        setTimeout(() => {
          this.$refs.bmap.update(onlineData, offlineData, alarmData)
        }, 1000)

      })
    })

    // this.$get("admin/home/deviceDistribution").then(res => {
    //     this.$refs.distributionChart.setData(res.data)
    // })

  },
};
</script>
<style lang="less" scoped>
.map-area {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 700px;
  position: relative;
}

.full-screen {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  position: fixed;
  border-radius: 0px;
}

.btn-full-screen{
  position: absolute;
  top: 10px;
  right: 10px;
}

</style>
