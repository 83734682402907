<!--递归，无限层级导航栏-->
<template>
    <div class="menu-container" v-bind:class="{'menu-container-collapsed':collapsed}">
        <a-menu :mode="mode" :theme="theme" :openKeys="openKeys" :selectedKeys="selectedKeys"
                @openChange="onOpenChange" style="background: transparent;border-right: none"
                :inline-collapsed="collapsed">
            <template v-for="menu in menuData">
                <recursion-sub-menu :menu="menu"/>
            </template>
        </a-menu>
        <div class="collapsed-icon" @click="collapsed=!collapsed">
            <a-icon v-if="collapsed" type="right" />
            <a-icon v-else type="left" />
        </div>
    </div>
</template>


<script>
    import RecursionSubMenu from './RecursionSubMenu.vue'

    export default {
        components: {RecursionSubMenu},
        name: 'IMenu',
        props: {
            menuData: {
                type: Array,
                required: true
            },
            theme: {
                type: String,
                required: false,
                default: 'dark'
            },
            mode: {
                type: String,
                required: false,
                default: 'inline'
            },
            // collapsed: {
            //     type: Boolean,
            //     required: false,
            //     default: false
            // }
        },
        data() {
            return {
                collapsed:false,
                openKeys: [],
//        cachedOpenKeys:[],
                selectedKeys: [],
            }
        },
        created() {
            this.updateMenu()
        },
        watch: {
            collapsed(val) {
                if (val) {
                    this.cachedOpenKeys = this.openKeys
                    this.openKeys = []
                } else {
                    this.openKeys = this.cachedOpenKeys
                }
            },
            '$route': function () {
                this.updateMenu()
            }
        },
        methods: {
            onOpenChange(openKeys) {
                //自动收缩其他项
                if (openKeys.length >= 2) {
                    this.openKeys = this.getSubPath(openKeys[openKeys.length - 1])
                } else {
                    this.openKeys = openKeys
                }
            },
            getSubPath(path) {
                var subPathArr = []
                subPathArr.push(path)
                while (path.lastIndexOf("/") > 0) {
                    path = path.substr(0, path.lastIndexOf("/"))
                    subPathArr.push(path)
                }
                return subPathArr
            },
            updateMenu() {
                var path = this.$route.path
                this.openKeys = this.getSubPath(path)
                this.selectedKeys = this.getSubPath(path)
            },
        },
    }
</script>

<style lang="less">
    /*@import "../../../static/less/Zwasu.less";*/
    .ant-menu-submenu > .ant-menu {
        background: #FFF !important;
    }

    .ant-menu-sub.ant-menu-inline{
        background: transparent !important;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: rgba(255, 255, 255, 0.7) !important;
    }

    .menu-container {
        position: relative;
        min-width: 200px;

        .collapsed-icon{
            position: absolute;
            bottom: 50%;
            right: 0;
            padding: 10px 2px;
            background: #F8F8F8;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            box-shadow: 0 0 4px #AAA;
            cursor: pointer;
        }

        .collapsed-icon:hover {
            background: #EEE;
        }
    }

    .menu-container-collapsed{
        min-width: 0px;
        padding: 0 5px;
    }

</style>
